import { requestAsync as rqRequestAsync } from 'redux-query';
import * as types from '../actions/actionTypes';
import store from 'Store/index';

/**
 * Creates a `redux-query` request will all the prepopulated values, e.g. `jwt` and `host`.
 * Can be used in the library's `useRequest`/`useRequests` hooks.
 */
const getRequestAsync = ({
	path,
	params = {},
	transform = (data) => data,
	update,
	force = false
}) => {
	const { api } = store.getState().data;
	if (!api.jwt) {
		return new Promise((resolve, reject) => reject(new Error('Unauthorized')));
	}

	const query = new URLSearchParams({ ...params, jwt: api.jwt }).toString();
	const protocol = window.Playwright ? 'http' : 'https';
	const url = `${protocol}://${api.host}${path}?${query}`;

	const jobAwareTransform = (payload) => {
		if (!payload.job_id) return transform(payload);

		store.dispatch(jobInProgress({ jobId: payload.job_id, transform, update }));
		return {};
	};

	return rqRequestAsync({
		url,
		transform: jobAwareTransform,
		update,
		force,
		options: { headers: { Accept: 'application/json' } }
	});
};

/**
 * Invokes the generated `redux-query` request as an action.
 * Can be used with the `dispatch` function call.
 */
const requestAsync =
	({ path, params = {}, transform = (data) => ({ ...data }), update, force = false }) =>
	(dispatch) =>
		dispatch(getRequestAsync({ path, params, transform, update, force }));

const jobIntervals = {};

const jobInProgress =
	({ jobId, transform, update }) =>
	(dispatch) => {
		dispatch({
			type: types.JOB_IN_PROGRESS,
			payload: { jobId, transform, update }
		});

		jobIntervals[jobId] = setInterval(() => dispatch(jobReady(jobId)), 5000);
	};

const jobReady = (jobId) => (dispatch, getState) => {
	dispatch({ type: types.JOB_READY, payload: { jobId } });

	const state = getState();
	const job = state.jobs[jobId];
	if (job) {
		dispatch(
			requestAsync({
				path: `/jobs/${jobId}/results`,
				transform: job.transform,
				update: job.update,
				force: true
			})
		)
			.then((res) => {
				if (res.status !== 200) return;

				dispatch(jobComplete(jobId));
				clearInterval(jobIntervals[jobId]);
			})
			.catch((err) => console.error(err.message));
	}
};

const jobComplete = (jobId) => ({
	type: types.JOB_COMPLETE,
	payload: { jobId }
});

export { getRequestAsync, requestAsync, jobReady };
