/* eslint-disable react/no-danger */
import popoverStyles from '../SelectorPanel/PopoverSelector.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import StudentList from '../StudentList';
import NudgeArrow from '../../components/NudgeArrow';
import { viewTemplateTypes } from '../../constants';
import {
	StudentIdsSelector,
	NoMatchingStudentsSelector
} from 'Containers/StudentList/StudentsSelector';
import { updateCurrentViewParams } from 'Actions/uiActions';
import Filters from './Filters';

class StudentSelector extends React.Component {
	static propTypes = {
		selectedScopeId: PropTypes.string,
		selectedStudentId: PropTypes.number,
		currentViewTemplate: PropTypes.string.isRequired,
		pages: PropTypes.object.isRequired,
		elements: PropTypes.object.isRequired,
		users: PropTypes.object.isRequired,
		studentIds: PropTypes.array.isRequired,
		studentsFilters: PropTypes.array,
		studentsFilteringEnabled: PropTypes.bool,
		noMatchingStudents: PropTypes.bool,
		dispatch: PropTypes.func.isRequired
	};

	state = {
		isPopoverOpen: false
	};

	handleSelectorClick = (event) => {
		event.preventDefault();

		if (event.currentTarget.classList.contains('popoverSelector')) {
			this.setState({ isPopoverOpen: true, anchorEl: event.currentTarget });
		}
	};

	setPopoverClosed = () => this.setState({ isPopoverOpen: false });

	onStudentSelect = (event, value) => {
		this.props.dispatch(updateCurrentViewParams({ student: value }));
		this.setPopoverClosed();
	};

	onOverallClassPerformanceSelect = () => {
		this.props.dispatch(
			updateCurrentViewParams({ student: null, scope: this.props.selectedScopeId })
		);
		this.setPopoverClosed();
	};

	onPrevStudentClick = () => {
		const { selectedStudentId, studentIds } = this.props;
		let prevStudentId = null;

		if (studentIds.length > 0 && selectedStudentId) {
			const currentIndex = studentIds.indexOf(selectedStudentId);
			if (currentIndex > 0) {
				prevStudentId = studentIds[currentIndex - 1];
			}
		}

		this.props.dispatch(updateCurrentViewParams({ student: prevStudentId }));
	};

	onNextStudentClick = () => {
		const { selectedStudentId, studentIds } = this.props;
		let nextStudentId = null;

		// overall class performance
		if (selectedStudentId === null) {
			nextStudentId = studentIds[0];
		}

		if (studentIds.length > 0 && selectedStudentId) {
			nextStudentId = studentIds[studentIds.indexOf(selectedStudentId) + 1];
		}

		this.props.dispatch(updateCurrentViewParams({ student: nextStudentId }));
	};

	isFilteringEnabled = () => {
		const { studentsFilteringEnabled, currentViewTemplate, selectedScopeId, pages, elements } =
			this.props;
		if (!studentsFilteringEnabled) return false;

		if (currentViewTemplate === viewTemplateTypes.PAGE) {
			return pages[selectedScopeId].element_ids.some(
				(id) => elements[id].type === 'interactive_template'
			);
		}

		return false;
	};

	getSelectedItemLabel = () => {
		const { selectedStudentId, noMatchingStudents, users } = this.props;

		if (selectedStudentId === null) return 'Overall Class Performance';

		const user = users[selectedStudentId];
		return user && !noMatchingStudents ? `${user.first_name} ${user.last_name}` : '-----';
	};

	render() {
		const {
			selectedStudentId,
			studentIds,
			studentsFilters,
			noMatchingStudents,
			showOverallClassPerformance
		} = this.props;
		const filteringEnabled = this.isFilteringEnabled();

		const selectedItemLabel = this.getSelectedItemLabel();

		let backArrowDisabled = false;
		let forwardArrowDisabled = false;
		if (noMatchingStudents || studentIds.length === 0) {
			backArrowDisabled = forwardArrowDisabled = true;
		}
		if (!showOverallClassPerformance && selectedStudentId === studentIds[0]) {
			backArrowDisabled = true;
		}
		if (selectedStudentId === studentIds[studentIds.length - 1]) {
			forwardArrowDisabled = true;
		}
		// overall class performance
		if (selectedStudentId === null) {
			backArrowDisabled = true;
			forwardArrowDisabled = false;
		}

		return (
			<div className={popoverStyles.Base}>
				<Button
					className="popoverSelector"
					onClick={this.handleSelectorClick}
					classes={{
						root: popoverStyles.SelectorLabelButton
					}}>
					<div className={popoverStyles.SelectorLabelBox}>
						<span className={popoverStyles.SelectorLabel}>Student</span>
						<div className={popoverStyles.SelectorLabelIndicators}>
							{filteringEnabled && studentsFilters.length > 0 && (
								<span className={popoverStyles.SelectorLabelFiltersApplied}>Filtered</span>
							)}
							<div className={popoverStyles.PopoverSelectorLabelArrowBox}>
								<Icon className={cn('ss-navigateright', popoverStyles.PopoverSelectorLabelArrow)} />
							</div>
							<div
								data-active={this.state.isPopoverOpen}
								className={popoverStyles.SelectorLabelIndicator}>
								&nbsp;
							</div>
							{/* Need &nbsp; to give height in Safari */}
						</div>
					</div>
				</Button>

				<Popover
					open={this.state.isPopoverOpen}
					anchorEl={this.state.anchorEl}
					anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					onClose={this.setPopoverClosed}
					PaperProps={{ square: true }}>
					<StudentList
						onSelect={this.onStudentSelect}
						onOverallClassPerformanceSelect={this.onOverallClassPerformanceSelect}
						filteringEnabled={filteringEnabled}
						onClose={this.setPopoverClosed}
					/>
				</Popover>

				<div className={popoverStyles.PopoverSelectionLabelBox}>
					<span
						className={popoverStyles.PopoverSelectionLabelText}
						dangerouslySetInnerHTML={{ __html: selectedItemLabel }}
					/>
					<div className={popoverStyles.NudgeArrowsBox}>
						<NudgeArrow
							direction="back"
							onClick={this.onPrevStudentClick}
							disabled={backArrowDisabled}
						/>
						<NudgeArrow
							direction="forward"
							onClick={this.onNextStudentClick}
							disabled={forwardArrowDisabled}
						/>
					</div>
				</div>

				{filteringEnabled && <Filters studentsFilters={studentsFilters} />}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedScopeId: state.ui.currentViewParams.scope,
	selectedStudentId: state.ui.currentViewParams.student,
	currentViewTemplate: state.ui.viewTemplate,
	pages: state.entities.pages,
	elements: state.entities.elements,
	users: state.entities.users,
	studentIds: StudentIdsSelector(state),
	studentsFilters: state.students.filters,
	studentsFilteringEnabled: state.students.filteringEnabled,
	noMatchingStudents: NoMatchingStudentsSelector(state),
	showOverallClassPerformance: state.entities.featureFlags.showOverallClassPerformance
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(StudentSelector);
