/* eslint-disable react/no-danger */
import popoverStyles from '../SelectorPanel/PopoverSelector.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import TocMenu from '../TocMenu';
import NudgeArrow from 'Components/NudgeArrow';
import { viewTemplateTypes } from '../../constants';
import TocOutlineSelector from 'Containers/TocMenu/TocOutlineSelector';
import { tocFilterLabels } from 'Containers/TocMenu/TocFilters';
import { updateCurrentViewParams } from 'Actions/uiActions';
import FilterExplanation from './FilterExplanation';

class ScopeSelector extends React.Component {
	static propTypes = {
		selectedScopeId: PropTypes.string,
		selectedScopeType: PropTypes.string,
		currentViewTemplate: PropTypes.string.isRequired,
		gridViewAspect: PropTypes.string.isRequired,
		toc: PropTypes.object,
		chapters: PropTypes.object,
		pageGroups: PropTypes.object,
		pages: PropTypes.object,
		style: PropTypes.object,
		tocOutline: PropTypes.object,
		tocFilters: PropTypes.array,
		tocFilteringEnabled: PropTypes.bool,
		dispatch: PropTypes.func.isRequired
	};

	state = {
		isPopoverOpen: false
	};

	handleSelectorClick = (event) => {
		event.preventDefault();

		if (event.currentTarget.classList.contains('popoverSelector')) {
			this.setState({ isPopoverOpen: true, anchorEl: event.currentTarget });
		}
	};

	setPopoverClosed = () => this.setState({ isPopoverOpen: false });

	onScopeSelect = (event, value) => {
		this.props.dispatch(updateCurrentViewParams({ scope: value }));
		this.setPopoverClosed();
	};

	onPrevScopeClick = () => {
		const { selectedScopeId, selectedScopeType, tocOutline } = this.props;
		let prevScopeId = null;

		if (selectedScopeId) {
			const scopeIds = tocOutline[`${selectedScopeType}Ids`];
			const currentIndex = scopeIds.indexOf(selectedScopeId);
			if (currentIndex > 0) {
				prevScopeId = scopeIds[currentIndex - 1];
			}
		}

		this.props.dispatch(updateCurrentViewParams({ scope: prevScopeId }));
	};

	onNextScopeClick = () => {
		const { selectedScopeId, selectedScopeType, tocOutline } = this.props;
		let nextScopeId = null;

		if (!selectedScopeId) {
			nextScopeId = tocOutline.pageIds[0];
		} else {
			const scopeIds = tocOutline[`${selectedScopeType}Ids`];
			nextScopeId = scopeIds[scopeIds.indexOf(selectedScopeId) + 1];
		}

		this.props.dispatch(updateCurrentViewParams({ scope: nextScopeId }));
	};

	render() {
		const {
			currentViewTemplate,
			gridViewAspect,
			selectedScopeId,
			toc,
			chapters,
			pageGroups,
			pages,
			tocOutline,
			tocFilters,
			tocFilteringEnabled
		} = this.props;
		const { chapterIds, pageGroupIds, pageIds } = tocOutline;
		const isTableOfContentsDisabled = currentViewTemplate === viewTemplateTypes.ELEMENT;
		const isScopeSelectorDisabled =
			currentViewTemplate === viewTemplateTypes.ELEMENT ||
			(currentViewTemplate === viewTemplateTypes.GRID && gridViewAspect === 'gradebookPoints');

		let selectedItemLabel;

		const chapterNamesIncludeNumbering = toc.config.chapters.chapter_names_include_numbering;
		if (selectedScopeId) {
			if (pages[selectedScopeId]) {
				const page = pages[selectedScopeId];
				selectedItemLabel = page.page_name;
				if (page.page_nav_label) {
					selectedItemLabel = '<em>' + page.page_nav_label + ':</em> ' + selectedItemLabel;
				}
				if (page.page_number) {
					selectedItemLabel = page.page_number + ' ' + selectedItemLabel;
				}
			}
			if (pageGroups[selectedScopeId]) {
				const pageGroup = pageGroups[selectedScopeId];
				selectedItemLabel = pageGroup.name;
				if (pageGroup.page_group_number) {
					selectedItemLabel = pageGroup.page_group_number + ' ' + selectedItemLabel;
				}
			}
			if (chapters[selectedScopeId]) {
				const chapter = chapters[selectedScopeId];
				selectedItemLabel = chapter.chapter_name;
				if (chapter.chapter_number && !chapterNamesIncludeNumbering) {
					selectedItemLabel = chapter.chapter_number + ' ' + selectedItemLabel;
				}
			}
		} else {
			const pageGroupText = Object.keys(pageGroups).length > 0 ? ' or Page Group' : '';
			selectedItemLabel = `Select Chapter${pageGroupText}`;
		}

		const filteringEnabled = currentViewTemplate !== viewTemplateTypes.GRID && tocFilteringEnabled;
		let filtersLabel = 'ALL';

		if (tocFilters.length) {
			filtersLabel = tocFilterLabels[tocFilters[0]];
		}
		if (tocFilters.length > 1) {
			filtersLabel += ', ...';
		}

		const onFirstScopeElement = () => {
			const firstPage = selectedScopeId === pageIds[0];
			const firstPageGroup = selectedScopeId === pageGroupIds[0];
			const firstChapter = selectedScopeId === chapterIds[0];
			return firstPage || firstPageGroup || firstChapter;
		};

		const onLastScopeElement = () => {
			const lastPage = selectedScopeId === pageIds[pageIds.length - 1];
			const lastPageGroup = selectedScopeId === pageGroupIds[pageGroupIds.length - 1];
			const lastChapter = selectedScopeId === chapterIds[chapterIds.length - 1];
			return lastPage || lastPageGroup || lastChapter;
		};

		let backArrowDisabled = isScopeSelectorDisabled;
		let forwardArrowDisabled = isScopeSelectorDisabled;

		if (!selectedScopeId) {
			backArrowDisabled = forwardArrowDisabled = true;
		}
		if (onFirstScopeElement()) {
			backArrowDisabled = true;
		}
		if (onLastScopeElement()) {
			forwardArrowDisabled = true;
		}

		return (
			<div className={popoverStyles.Base}>
				<Button
					className="popoverSelector"
					disabled={isTableOfContentsDisabled}
					onClick={this.handleSelectorClick}
					classes={{
						root: popoverStyles.SelectorLabelButton
					}}>
					<div className={popoverStyles.SelectorLabelBox}>
						<span
							aria-label="Show Table of Contents"
							disabled={isTableOfContentsDisabled}
							className={popoverStyles.SelectorLabel}>
							Table of Contents
						</span>
						<div className={popoverStyles.SelectorLabelIndicators}>
							{filteringEnabled && (
								<span
									aria-label={`Viewing ${filtersLabel}`}
									className={popoverStyles.SelectorLabelFiltersApplied}>
									{filtersLabel}
								</span>
							)}
							<div className={popoverStyles.PopoverSelectorLabelArrowBox}>
								<Icon
									disabled={isTableOfContentsDisabled}
									className={cn('ss-navigateright', popoverStyles.PopoverSelectorLabelArrow)}
								/>
							</div>
							<div
								data-active={this.state.isPopoverOpen}
								className={popoverStyles.SelectorLabelIndicator}>
								&nbsp;
							</div>
							{/* Need &nbsp; to give height in Safari */}
						</div>
					</div>
				</Button>

				<Popover
					open={this.state.isPopoverOpen}
					anchorEl={this.state.anchorEl}
					anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					onClose={this.setPopoverClosed}
					PaperProps={{ square: true }}>
					<TocMenu onSelect={this.onScopeSelect} />
				</Popover>

				<div className={popoverStyles.PopoverSelectionLabelBox}>
					<span
						disabled={isScopeSelectorDisabled}
						className={popoverStyles.PopoverSelectionLabelText}
						dangerouslySetInnerHTML={{ __html: selectedItemLabel }}
					/>
					<div className={popoverStyles.NudgeArrowsBox}>
						<NudgeArrow
							direction="back"
							onClick={this.onPrevScopeClick}
							disabled={backArrowDisabled}
						/>
						<NudgeArrow
							direction="forward"
							onClick={this.onNextScopeClick}
							disabled={forwardArrowDisabled}
						/>
					</div>
				</div>

				{filteringEnabled && tocFilters.length > 0 && <FilterExplanation tocFilters={tocFilters} />}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedScopeId: state.ui.currentViewParams.scope,
	selectedScopeType: state.entities.scopeTypes[state.ui.currentViewParams.scope],
	currentViewTemplate: state.ui.viewTemplate,
	gridViewAspect: state.ui.currentViewParams.gridViewAspect,
	toc: state.entities.toc,
	chapters: state.entities.chapters,
	pageGroups: state.entities.page_groups,
	pages: state.entities.pages,
	tocOutline: TocOutlineSelector(state),
	tocFilters: state.toc.filters,
	tocFilteringEnabled: state.toc.filteringEnabled
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ScopeSelector);
